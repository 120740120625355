import VueRouter from 'vue-router';

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../pages/Home.vue')
  },
  {
    path: '/ethic',
    name: 'ethic',
    component: () => import('../pages/Ethic.vue'),
  }
];

export const router = new VueRouter(
  { 
    mode: 'history', 
    routes, 
    scrollBehavior() {
      return { y: 0, x: 0 }
    }
  });